import { LinkList, NavbarContainer } from "./NavbarElements";
import { ListItem } from "./NavbarElements";
import { LinkItem } from "./NavbarElements";
import { PrimaryButton } from '../buttons/ButtonElements';
function Navbar () {
  return (
  <NavbarContainer>
    <LinkList>
      <LinkItem to="/">Home</LinkItem>
      <ListItem><LinkItem to="/Academia">Academic Experience</LinkItem></ListItem>
      <ListItem><LinkItem to="/Industry">Industrial Experience</LinkItem></ListItem>
      <ListItem>Contact</ListItem>
      <ListItem><LinkItem to="/Students"><PrimaryButton>For Students</PrimaryButton></LinkItem></ListItem>
    </LinkList>
  </NavbarContainer>)

}

export default Navbar;
