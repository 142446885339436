import styled from 'styled-components'

export const PrimaryButton = styled.button`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  background-color: var(--primary-blue);
  padding: 1rem;
  border-radius: 24px;
  color: var(--white);
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-purple);
    transition: all 0.3s ease-in-out;
  }
`
