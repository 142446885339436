import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import ListItemImg from '../../assets/list-item.svg';

export const NavbarContainer = styled.nav`
  display: grid;
  grid-template-columns: repeat(12,1fr);
  column-gap: 10px;
  background-color: transparent;
  position:fixed;
  z-index: 10000;
  width: 100%;
  padding: 1rem 0rem;

`
export const LinkList = styled.ul`
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
  list-style-image: url(${ListItemImg});
  grid-column-start: 2;
  grid-column-end: 12;
  border-radius: 100px;
  padding: 1rem;
  background-color: var(--white);
`

export const ListItem = styled.li`
  padding-inline-start: 2rem;
`
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: var(--dark);
`
