import './App.css';
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Academia from './Pages/Academia/Academia';
import Students from './Pages/Students/Students';
import Industry from './Pages/Industry/Industry';
import Navbar from './Components/navbar';

const AppLayout = () => (
  <>
    <Navbar />
    <Outlet />
  </>

);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/academia",
        element: <Academia />
      },
      {
        path: "/industry",
        element: <Industry />
      },
      {
        path: "/students",
        element: <Students />
      }
    ]
  }
])



function App() {
  return <RouterProvider router={router} />;
}

export default App;
