import styled from 'styled-components'

export const TextLarge = styled.p`
  font-family: 'Moglite';
  font-size: 76px;
  color: var(--primary-blue);
  line-height: normal;
  align-self: ${(props) => props.alignment ? props.alignment : 'left'};
  line-height: 1.2;
`

export const TextMedium = styled.p`
  font-family: 'Moglite';
  font-size: 54px;
  color: var(--primary-blue);
  line-height: normal;
  align-self: ${(props) => props.alignment ? props.alignment : 'left'};
  line-height: 1.2;
`
export const TextCopy = styled.p`
  color: var(--dark);
  line-height: 1.5;
  text-wrap: balanced;
  text-align: ${(props) => props.alignment ? props.alignment : 'left'};
`
