import React, { useEffect, useRef } from 'react'

const StillLoading = () => {
  const text = useRef();
  const repeats = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      repeats.current = (repeats.current + 1) % 4;
      text.current.innerText = '⌛'.repeat(repeats.current);
    }, 500);
    return () => clearInterval(interval);
  })

  return (
    <h3 ref={text}>

    </h3>
  )
}

export default StillLoading;
