import styled from 'styled-components'
import { TextCopy } from '../../Components/text/TextElements'

export const HomeContainer = styled.div`
  padding-top: 120px;
  width:100%;
  background-color: var(--light);
`

export const WelcomeSection = styled.section`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  column-gap: 10px;

`
export const WelcomeImage = styled.img`
  grid-column-start: 3;
  grid-column-end: 7;
  width: auto;
  height: 80vh;
  padding-top: 1rem;
`
export const WelcomeText = styled.article`
  grid-column-start: 7;
  grid-column-end: 12;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 25vh;
`
export const QuoteWrapper = styled.div`
  height: fit-content;
  width: fit-content;
  margin-left: -150px;
  position: relative;
`;

export const WelcomeQuote = styled.p`
  background-color: var(--white);
  height: 100px;
  width: 400px;
  padding: 1rem;
  line-height: normal;
  text-wrap: balanced;
`
export const OffsetBorder = styled.div`
  position: absolute;
  border: 1px solid var(--primary-blue);
  height: 100px;
  width: 400px;
  transform: translate(7px, 7px);
`;

export const AboutSection = styled.section`
  height: auto;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  column-gap: 10px;
  padding-bottom: 2rem;
  grid-auto-flow: row;

`
export const AboutHeader = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 12;
  justify-content: center;
  row-gap: 0rem;
`

export const AboutDecoration = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;

`
export const Line = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--primary-blue);
`

export const AboutCopy = styled(TextCopy)`
grid-column-start: 2;
grid-column-end: 12;
padding: 6rem 3rem;
`
