import { HomeContainer, WelcomeImage, WelcomeQuote, WelcomeSection, WelcomeText, OffsetBorder, QuoteWrapper, AboutSection, AboutDecoration, AboutHeader, Line, AboutCopy } from "./HomeElements";
import TomusFoto from "../../assets/hp-foto.png";
import OneStar from '../../assets/single-star.svg';
import TwoStars from '../../assets/star-double.svg'
import {TextLarge, TextMedium} from "../../Components/text/TextElements";
const Home = () => {

  return (
    <HomeContainer>
      <WelcomeSection>
        <WelcomeImage src={TomusFoto} />
        <WelcomeText>
          <TextLarge>
            {"\u{F007}ell\u{F026} t\u{F030}e\u{F027}e!"}
          </TextLarge>
          <TextMedium>
            {"I'm \u{F013}\u{F026}mas\u{F02E} \u{F012}\u{F02E}andała"}
          </TextMedium>
          <QuoteWrapper>
          <OffsetBorder>
            </OffsetBorder>
            <WelcomeQuote>
              Yesterday is a history, tomorrow is a mystery but today is a gift. This is why it is called the present.
            </WelcomeQuote>
            </QuoteWrapper>
            </WelcomeText>
      </WelcomeSection>
      <AboutSection>
        <AboutHeader>
          <TextLarge alignment="center">{"Ab\u{F026}ut me"}</TextLarge>
          <AboutDecoration>
            <img src={OneStar} alt="" />
            <Line></Line>
            <img src={TwoStars} alt="" />
          </AboutDecoration>
        </AboutHeader>
        <AboutCopy alignment="center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas accumsan quam turpis, vitae scelerisque eros ornare at. Quisque suscipit porttitor magna, nec congue libero consequat vitae. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer eu condimentum nibh, non sollicitudin nisl. Quisque vitae odio condimentum, vestibulum eros porta, dapibus odio. Praesent nibh diam, dapibus vitae eros vitae, interdum elementum velit. Mauris fringilla, neque sed auctor maximus, odio nisi pellentesque massa, a vulputate ipsum sem ac neque.

        <br></br><br></br>am auctor ullamcorper imperdiet. Curabitur quis mollis purus, et tempor arcu. Pellentesque at diam non ante vehicula lacinia. Pellentesque malesuada ornare urna id pharetra. Nam et ipsum mi. Donec porta maximus odio, eget ultricies arcu efficitur in. Duis eget ipsum luctus, efficitur quam ac, rhoncus nisl. Donec et tristique est. Nam eu sapien eget nulla fringilla eleifend.


        <br></br><br></br>Mauris eget dignissim augue. Cras luctus non nisi at aliquet. Duis at turpis elit. Cras mollis laoreet lacus et rhoncus. Mauris eu cursus dui. Fusce eget nisl quis erat euismod consequat. Fusce dictum eleifend pulvinar. Duis bibendum elit sed justo vehicula, nec dapibus dui facilisis. Pellentesque aliquam euismod turpis nec consectetur. Morbi mattis, augue ac aliquet porta, mauris lacus congue eros, venenatis maximus sapien magna eget magna. Vestibulum nunc mauris, ornare at diam eu, lacinia fringilla massa.


        <br></br><br></br>Aliquam aliquam mollis purus imperdiet consectetur. Phasellus est odio, vulputate nec purus ac, egestas blandit lorem. Cras leo nulla, luctus eget diam eget, feugiat vulputate dui. Morbi vulputate tortor vel libero varius hendrerit. Aenean volutpat dolor vitae enim elementum, sed dapibus lorem pellentesque. Duis vitae orci bibendum, hendrerit nisi vel, maximus lorem. Donec eget urna eget augue tempus egestas a sit amet velit.
        </AboutCopy>
      </AboutSection>
    </HomeContainer>
  );
}

export default Home;
