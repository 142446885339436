import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const useLocalData = (url) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    axios
      .get(`/data/${url}.json`)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, loading, error]
}

export default useLocalData;
