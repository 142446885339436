import React from 'react';
import useLocalData from '../../utils/useFetch';
import StillLoading from '../../Components/StillLoading';

const Academia = () => {
  const [publications, loading] = useLocalData("publications");
  const publicationsHTML = publications.map(it => <p key={it.id}>{it.title}</p>);

  return (
    <div>
      <h2>Publikacje, nagrody</h2>
      {loading ? <StillLoading/> : publicationsHTML}
    </div>
  )
}

export default Academia;
